<template>
  <div>
    <b-table-simple 
      v-if="extracts.length"
      id="extract-table" 
      responsive 
      borderless 
      fixed
    >
      <thead>
        <tr>
          <th width="5%">
            <label class="checkbox">
              <input type="checkbox" v-model="selectAll" @click="selectAllChecks" />
              <span class="checkmark">
                <Check />
              </span>
            </label>
          </th>
          <th>Status</th>
          <th>N° do extrato</th>
          <th>Favorecido</th>
          <th>Valor total</th>
          <th>Valor repasse</th>
          <th>Extrato</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(extract, index) in extracts" :key="index">
          <td width="5%">
            <Checkbox              
              :value="Boolean(selected.find(select => select.id === extract.id))"
              @change="selectCheck(extract)" 
              :key="selected" 
              :disabled="extract.deleted_at"
            />
          </td>
          <td>
            <span
              class="extract-status"
              :class="extract?.status.toLowerCase().replace(/\s/g, '')"
            >
              {{ extract.status }}
            </span>
          </td>
          <td>
            <span class="extract-status">
              Nº {{ extract.number}}
            </span>
          </td>
          <td>
           <span class="extract-status">
              {{ extract.beneficiary.name}}
            </span>
          </td>
          <td>
           <span class="extract-status">
              {{ parseNumberToMoney(extract.total_value)}}
            </span>
          </td>
          <td>
            <span class="extract-status">
              {{ parseNumberToMoney(extract.onlending_value)}}
            </span>
          </td>

          <td>
            <b-button variant="link" @click="openExtractModal(extract.id)">
              Visualizar
            </b-button>
            <!-- <div class="options">
              <MoreVertical class="more-icon" />
              <div class="menu">
                <b-button 
                  v-can="'RepMedExt4'"
                  variant="link"
                  @click="openAttachedFilesModal(extract)"
                > 
                  Anexar arquivos 
                </b-button>
                <b-button
                  v-can="'RepMedExt5'"
                  v-if="!extract.deleted_at && !extract.bill_id"
                  variant="link remove mb-0"
                  @click="deleteExtractClick(extract)"
                >
                  Excluir extrato
                </b-button>
              </div>
            </div> -->
          </td>
          <td>
            <div class="actions">
              <FileUpload 
                class="pointer"
                v-can="'RepMedExt4'"
                v-b-tooltip.hover title="Anexar arquivos"
                @click="openAttachedFilesModal(extract)"
              />
              <Delete 
                class="pointer"
                v-can="'RepMedExt5'"
                v-if="!extract.deleted_at && !extract.bill_id"
                v-b-tooltip.hover title="Excluir extrato"
                @click="deleteExtractClick(extract)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <NoFiltersResults
      v-else
      message="Os filtros aplicados não geraram nenhum resultado." 
    />
  </div>
</template>

<script>
import api from '@/modules/onlending/api.js'
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  name: 'Table',
  components: {
    Check: () => import('@/assets/icons/check.svg'),
    FileUpload: () => import('@/assets/icons/file-upload.svg'),
    Delete: () => import('@/assets/icons/delete-new.svg'),
    Checkbox: () => import('@/components/General/Check'),
    NoFiltersResults: () => import('@/components/General/noFilterResults'),
  },
  props: {
    checkable: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      default: () => []
    },
    extracts: {
      type: Array,
      default: () => []
    },
    updateStatus: {
      type: Function,
      default: () => {}
    },
    updateOnledingStatus: {
      type: Function,
      default: () => {}
    },
    openAttachedFilesModal: {
      type: Function,
      default: () => {}
    },
    openExtractModal: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      selectAll: false,
      selected: [],
      extract: {}
    }
  },
  methods: {
    parseNumberToMoney,
    deleteExtractClick(data) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente excluir este extrato? Essa ação não poderá ser desfeita.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          this.deleteExtract(data)
        }
      })
    },
    deleteExtract(data) {
      const isLoading = this.$loading.show()
      api.deleteOnlendingExtract(data.id)
      .then(() => {          
        this.$emit('delete-extract', data.id)
        this.$toast.success('Extrato excluído com sucesso!')
      })
      .catch ((error) => {
        this.$toast.error(error.message)
      }) 
      .finally (() => {
        isLoading.hide()
      })
    },
    selectAllChecks() {
      this.selected = []
      if (!this.selectAll) {
        this.extracts.forEach(row => {
          if (!row.deleted_at) {
            this.selected.push(row)
          }
        })
      }
      this.$emit('selected', this.selected)
    },
    selectCheck(row) {
      const index = this.selected.map(item => item.id).indexOf(row.id)
      if (index !== -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(row)
      }
    }
  },
  watch: {
    selected(value) {
      this.$emit('selected', value)
      if (!value.length) {
        this.selectAll = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#extract-table {
  position: relative;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  table-layout: fixed;

  thead {
    tr {
      background-color: var(--neutral-100);
    }
  }

  .hide-space {
    padding: 0px !important;
  }

  .spacing {
    height: 120px;
  }

  td:last-child {
    overflow: visible;
  }

  .conferred {
    color: var(--states-success-dark-green) !important;
  }

  .not-conferred {
    color: var(--light-orange-500);
  }


  td,
  th {
    padding: 25px 15px;
    max-width: 20ch;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media (max-width: 1368px) {
      font-size: 15px;
    }

    .checkbox {
      margin-bottom: 40%;
    }

    .bold {
      font-weight: 700;
    }

    .green {
      background-color: var(--states-success-soft-green);
      color: var(--states-success-dark-green);
    }

    .blue {
      background-color: var(--blue-100);
      color: var(--blue-500);
    }

    .yellow {
      background-color: #fff6d4;
      color: #bc8212;
    }

    .orange {
      background-color: #fff3e1;
      color: var(--orange);
    }

    .red {
      background-color: var(--light-orange-200);
      color: var(--states-error);
    }

    .toggle-btn {
      background: var(--blue-100);
      border: none;
      padding: 5px !important;

      .icon {
        width: 25px;
        transition: all 500ms;
      }
    }
  }

  .options {
    cursor: pointer;
    width: 24px;
    height: 28px;
    position: relative;
    display: inline-block;

    .more-icon {
      width: 24px;
      height: 24px;
      stroke: var(--neutral-500);
      transition: all 0.5s;
      cursor: pointer;
    }

    .menu {
      z-index: 10;
      width: 141px;
      position: absolute;
      top: 28px;
      right: 0;
      background: var(--neutral-000);
      border: 1px solid var(--neutral-200);
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
        0px 4px 10px -8px rgba(12, 29, 89, 0.2);
      border-radius: 8px;
      padding: 0;
      opacity: 0;
      transition: all 0.5s;
      flex-direction: column;
      align-items: start;
      display: none;

      .mtb-12 {
        margin: 12px 0;
      }

      .btn {
        font-weight: 600;
        width: 100%;

        &.remove {
          color: var(--states-error);
        }

        &.btn-link {
          text-align: left;
          padding: 12px 18px !important;
        }
      }
    }

    &:hover {
      .more-icon {
        transform: rotate(90deg);
      }

      .menu {
        opacity: 1;
        display: flex;
        z-index: 10;
      }
    }
  }

  .text-align-center {
    text-align: center;
  }

  .tag {
    border-radius: 30px;
    padding: 4px 10px;
  }
}

.checkbox {
  display: inline !important;
}
.extract-status {
  color: var(--type-active);
  width: fit-content;
  padding: 0.5rem 0.5rem;
  border-radius: 100px;
  &.aconferir {
    background-color: #fed2ce;
  }
  &.conferido {
    background-color: #e7ffdb;
  }
  &.enviado {
    background-color: var(--light-orange-200);
  }
  &.arevisar {
    background-color: #e2ccff;
  }
  &.apagar {
    background-color: #e6f5ff;
  }
  &.excluído {
    background-color: #e9ebf2;
  }
  &.finalizado {
    background-color: var(--blue-100);
  }
}

:deep(.line-through) {
  text-decoration: line-through !important;
}
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  .pointer {
    cursor: pointer;
  }
}
</style>